import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import collapse from '@alpinejs/collapse'
import cart from "../lib/cart";
import revealer from "@/lib/revealer";


// set up Alpine
window['Alpine'] = Alpine;

Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(collapse);

Alpine.store("cart", cart());

// Import all components from the components folder -- vite
const modules = import.meta.glob(['../components/*.ts', '../components/*.js'], {eager: true});

Object.entries(modules).forEach(([path, component]) => {
		const name = path.split('/').pop()?.split('.')[0]; // get the name from the file
		if (name) {
			Alpine.data(name, (component as any).default);
		}
});



// Mount Alpine
document.addEventListener('DOMContentLoaded', () => {
	revealer();
	Alpine.start();
});
