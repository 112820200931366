import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


export default (opt: any = {}) => 
({
	showMiniCart: false,

	...opt,

	init() {

		this.$watch('showMiniCart', (show) => {
			console.log('showMiniCart', show)
			const el = this.$el.querySelector('#minicart-wrapper');
			this.$nextTick(() => {
				if (show)
					disableBodyScroll(el);
				else
					enableBodyScroll(el);
			})
		})

		document.addEventListener('open-mini-cart', (event: Event) => {
			// console.log('open-mini-cart');
			this.showMiniCart = true;
		});
	},

})