

interface ProductionOptions {
	qty?: number;
	product: any;
	selected_variant?: any;
	price?: number;
	compare_price?: number;
}

export default (opt: ProductionOptions) =>
({
	qty: 1,
	selected_variant: null,
	// totalPrice: 0,
	price: 0,
	compare_price: 0,

	...opt,

	init()
	{
		// console.log('bundleProduct', this.bundleProduct);
		if (!this.product)
			throw 'product error: must set "product"';
		// if (!this.selected_variant)
		// 	throw 'product error: must set "selected_variant"';

		this.$watch('selected_variant', (newVal: any) => 
		{
			// console.log('selected_variant', newVal);
			// this.price = newVal.price;
			// this.compare_price = newVal.compare_price || 0;
			this.updateCartPrice();
		})

		this.onOptionChange();
	},


	updateCartPrice() {
		this.price = this.selected_variant.price;
		this.compare_price = this.selected_variant.compare_price || 0;
		
		if (this.compare_price) {
			this.addToCartString = `Add to cart - <s>${this.compare_price / 100}</s> ${this.price / 100}`;
		} else {
			this.addToCartString = `Add to cart - ${this.price / 100}`;
		}
	},

	onOptionChange() {
		const options = [...this.$root.querySelectorAll('input.product-option:checked')].map(x => x.value);
		// console.log('options', options);
		if (options.length > 0) {
			const optionsStr = JSON.stringify(options);
			this.product.variants.forEach(variant => {
				if (optionsStr == JSON.stringify(variant.options))
					this.selected_variant = variant;
			});
		}
		else {
			this.selected_variant = this.product.variants[0];
		}
	},


	addToCart(e: Event)
	{
		let objs: { id: string; quantity: number; selling_plan?: any }[] = [{
			id: this.selected_variant.id,
			quantity: this.qty,
		}];
		const plan = this.$root.querySelector('select[name="selling_plan"]');
		if (plan)
		{
			objs[0].selling_plan = plan.value;
		}
		// console.log(addToCart)
		return this.$store.cart.add(objs)
			.then(() =>
			{
				this.$dispatch('open-mini-cart');
			});
	},


})